import React from "react"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Button from "../components/button"
import TagList from "../components/tag-list"
import { getRoleIcon, getRoleName } from "../utils/role-util"
import PaperList from "../components/paper-list"
import TalkList from "../components/talk-list"
import MentionList from "../components/mention-list"
import ProjectList from "../components/project-list"
import TableOfContents from "../components/table-of-contents"

import { faGithub, faTwitter } from "@fortawesome/free-brands-svg-icons"
import {
    faArrowLeft,
    faClock,
    faEye,
    faGraduationCap,
    faMicrophone,
    faNewspaper,
    faPenNib,
    faProjectDiagram,
    faRocket,
    faTags
} from "@fortawesome/free-solid-svg-icons"
import {
    bottomViewButtonContainer,
    buttons,
    cover,
    coverContainer,
    creation,
    creationDate,
    tagMetadata,
    creationMetadata,
    dataName,
    heading,
    icon,
    metadata,
    metadataContainer,
    metadataSectionTitle,
    metadataSectionTitleButton,
    projectName,
    role,
    textSm
} from "./project.module.scss"
import SocialMediaPostList from "../components/social-media-post-list"
import Breadcrumb from "../components/breadcrumb"
import { getTagName, getTagUrl } from "../utils/tag-util"


function processRole(role) {
    return (
        <>
            <FontAwesomeIcon icon={getRoleIcon(role)} className={"fa-fw " + icon}/>
            <span>
                {getRoleName(role)}
            </span>
        </>
    )
}

function processDate(date, role) {
    let text = " in "
    if (date.length > 4) {
        text = " " + (date.length > 9 ? "on" : "in") + " "
        if (role === "solo" || role === "lead") {
            text = " launched" + text
        }
    }
    return (
        <>
            {text}
            <strong className={creationDate}>
                <FontAwesomeIcon icon={faClock} className={"fa-fw " + icon}/>
                {date}
            </strong>
        </>
    )

}

function shortenDate(date) {
    return date.substring(0, date.indexOf("T"))
}

const RelatedPapers = ({ papers, title, icon }) => {
    return <section id={"related-papers"}>
        <div className={metadataSectionTitle}>
            <h3>
                <FontAwesomeIcon icon={icon} className={"fa-fw header-icon"}/>
                {title}
            </h3>
            <div className={metadataSectionTitleButton}>
                <Button link={"/academic"}
                        local={true}
                        icon={faGraduationCap}
                        type={"secondary"}>
                    <span className={textSm}>View</span> all
                </Button>
            </div>
        </div>
        <PaperList papers={papers} displayProject={false}/>
    </section>
}
const RelatedTalks = ({ talks, project, title, icon }) => {
    return <section id={"related-talks"}>
        <div className={metadataSectionTitle}>
            <h3>
                <FontAwesomeIcon icon={icon} className={"fa-fw header-icon"}/>
                {title}
            </h3>
            <div className={metadataSectionTitleButton}>
                <Button link={"/appearances#talks"}
                        local={true}
                        icon={faMicrophone}
                        type={"secondary"}>
                    <span className={textSm}>View</span> all
                </Button>
            </div>
        </div>
        <TalkList talks={talks}/>
    </section>
}
const RelatedMentions = ({ mentions, title, icon }) => {
    return <section id={"related-mentions"}>
        <div className={metadataSectionTitle}>
            <h3>
                <FontAwesomeIcon icon={icon} className={"fa-fw header-icon"}/>
                {title}
            </h3>
            <div className={metadataSectionTitleButton}>
                <Button link={"/appearances#press"}
                        local={true}
                        icon={faNewspaper}
                        type={"secondary"}>
                    <span className={textSm}>View</span> all
                </Button>
            </div>
        </div>
        <MentionList mentions={mentions} displayProject={false}/>
    </section>
}
const RelatedTweets = ({ socialMediaPosts, project, title, icon }) => {
    return <section id={"related-tweets"}>
        <div className={metadataSectionTitle}>
            <h3>
                <FontAwesomeIcon icon={icon} className={"fa-fw header-icon"}/>
                {title}
            </h3>
        </div>
        {socialMediaPosts &&
            <SocialMediaPostList socialMediaPosts={socialMediaPosts}/>}
    </section>
}
const RelatedProjects = ({ projects, project, title, icon }) => {
    return <section id={"related-projects"}>

        <div className={metadataSectionTitle}>
            <h3>
                <FontAwesomeIcon icon={icon} className={"fa-fw header-icon"}/>
                {title}
            </h3>
            <div className={metadataSectionTitleButton}>
                <Button link={"/"}
                        local={true}
                        icon={faRocket}
                        type={"secondary"}>
                    <span className={textSm}>View</span> all
                </Button>
            </div>
        </div>
        <ProjectList projects={projects}/>
    </section>
}

function createRelatedContentList(project, projects, papers, mentions, socialMediaPosts, talks) {
    const relatedContent = []
    if (papers && papers.length > 0) {
        const title = (papers.length > 1 ? "Related papers" : "Paper about " + project.frontmatter.title)
        const icon = faPenNib
        relatedContent.push({
            "url": "#related-papers",
            "title": title,
            "icon": icon,
            "component": <RelatedPapers papers={papers.map(({ node }) => node)}
                                        title={title}
                                        icon={icon}
                                        key={"related-publications"}/>
        })
    }
    if (talks && talks.length > 0) {
        const title = "Talk" + (talks.length > 1 ? "s" : "") + " about " + project.frontmatter.title
        const icon = faMicrophone
        relatedContent.push({
            "url": "#related-talks",
            "title": title,
            "icon": icon,
            "component": <RelatedTalks talks={talks}
                                       project={project}
                                       title={title}
                                       icon={icon}
                                       key={"related-talks"}/>
        })
    }
    if (mentions && mentions.length > 0) {
        const title = "Press mention" + (mentions.length > 1 ? "s" : "")
        const icon = faNewspaper
        relatedContent.push({
            "url": "#related-mentions",
            "title": title,
            "icon": icon,
            "component": <RelatedMentions mentions={mentions}
                                          title={title}
                                          icon={icon}
                                          key={"related-mentions"}/>
        })
    }
    if (socialMediaPosts && socialMediaPosts.length > 0) {
        const title = "What people say about " + project.frontmatter.title
        const icon = faTwitter
        relatedContent.push({
            "url": "#related-tweets",
            "title": title,
            "icon": icon,
            "component":
                <RelatedTweets socialMediaPosts={socialMediaPosts}
                               project={project}
                               title={title}
                               icon={icon}
                               key={"related-tweets"}/>
        })
    }
    if (projects && projects.length > 0) {
        const title = "Project" + (projects.length > 1 ? "s" : "") + " related to " + project.frontmatter.title
        const icon = faRocket
        relatedContent.push({
            "url": "#related-projects",
            "title": title,
            "icon": icon,
            "component": <RelatedProjects projects={projects}
                                          project={project}
                                          title={title}
                                          icon={icon}
                                          key={"related-projects"}/>
        })
    }
    return relatedContent
}

const Project = ({ data }) => {

    const project = data.mdx

    // Related Projects
    const relatedProjects = project.relatedProjects

    // Other related
    const papers = data.allPapersJson.edges
    const mentions = data.allPressMentionsJson.edges
    const talks = data.relatedTalks.edges

    // Summarize all related
    const relatedContent = createRelatedContentList(project, relatedProjects, papers, mentions, project.relatedSocialMediaPosts, talks)
    const relatedContentTableOfContent = {
        "url": "#related-content",
        "title": "Related Content",
        "icon": faProjectDiagram,
        "iconSize": "sm",
        "items": relatedContent
    }
    const currentTableOfContents = project.tableOfContents.items || []
    const fullTableOfContents = { "items": [...currentTableOfContents, relatedContentTableOfContent] }

    // Metadata
    const modificationDate = shortenDate(project.parent.mtime)


    // Construct breadcrumbs
    const breadcrumbs = [{
        name: "Thomas Winters",
        link: "/"
    }, {
        name: "Projects",
        link: "/"
    }
    ]
    const nonMainTags = project.frontmatter.tags.filter(t => t !== "main")
    if (nonMainTags.length) {
        const mainTag = nonMainTags[0]
        breadcrumbs.push({
            name: getTagName(mainTag),
            link: getTagUrl(mainTag)
        })
    }
    breadcrumbs.push(
        {
            name: project.frontmatter.title
        }
    )

    const meta = [
        { name: "image", content: project.frontmatter.cover.publicURL },
        { name: "dateModified", content: modificationDate },
        { name: "publisher", content: data.site.siteMetadata.author },
        { name: "author", content: data.site.siteMetadata.author }
    ]
    if (project.frontmatter.blockSearchEngine) {
        meta.push({
            name: "robots",
            content: `noindex`
        })
    }

    return <>
        <Seo title={project.frontmatter.title}
             description={project.frontmatter.description}
             image={project.frontmatter.cover.publicURL}
             location={project.frontmatter.slug}
             largeImg={true}
             meta={meta}/>

        <Breadcrumb pages={breadcrumbs}/>
        <div className={coverContainer}>
            <GatsbyImage
                itemProp="image"
                image={project.frontmatter.cover.childImageSharp.gatsbyImageData}
                placeholder="blurred"
                className={cover}
                objectFit="cover"
                objectPosition="50% 50%"
                alt={`${project.frontmatter.title} cover image`}/>
        </div>

        <article itemScope itemType="http://schema.org/Article">
            <div className={heading}>
                <h1 className={projectName} itemProp="name">{project.frontmatter.title}</h1>
                <div className={buttons}>
                    {project.frontmatter.github &&
                        <Button icon={faGithub} link={project.frontmatter.github} type={"secondary"} text={"Code"}/>}
                    {project.frontmatter.url &&
                        <Button icon={faEye} link={project.frontmatter.url} type={"primary"}
                                text={"View " + project.frontmatter.title}/>}
                </div>
            </div>

            {/*<h2>Metadata</h2>*/}
            <section className={metadataContainer}>
                <div className={metadata + " " + creationMetadata}>
                    {/*<strong className={styles.dataName}>*/}
                    {/*<FontAwesomeIcon icon={faUserTie} className={"fa-fw " + styles.icon}/>*/}
                    {/*Role: {" "}*/}
                    {/*</strong>*/}
                    <span className={creation}>
                        <span className={role}>{processRole(project.frontmatter.role)}</span>
                        {processDate(project.frontmatter.date, project.frontmatter.role)}
                    </span>
                </div>
                <div className={metadata + " " + tagMetadata}>
                    <strong className={dataName}>
                        <FontAwesomeIcon icon={faTags} className={"fa-fw " + icon}/>
                        Tags: {" "}
                    </strong>
                    <TagList tags={project.frontmatter.tags}/>
                </div>
                {/*{project.frontmatter.technologies.length > 0 ? <div className={styles.metadata}>*/}
                {/*    <strong className={styles.dataName}>*/}
                {/*        <FontAwesomeIcon icon={faMicrochip} className={"fa-fw " + styles.icon}/>*/}
                {/*        Technologies: {" "}*/}
                {/*    </strong>*/}
                {/*    {project.frontmatter.technologies.map(t => (*/}
                {/*        <span key={t} className={styles.technology}>*/}
                {/*            {t} {" "}*/}
                {/*        </span>*/}
                {/*    ))}*/}
                {/*</div> : ""}*/}
            </section>

            {project.frontmatter.tableOfContents &&
                <>
                    <section>
                        <TableOfContents tableOfContents={fullTableOfContents}/>
                    </section>
                </>}


            <div itemProp="articleBody">
                <MDXRenderer tableOfContents={fullTableOfContents}>{project.body}</MDXRenderer>

            </div>
            {/*<div dangerouslySetInnerHTML={{ __html: project.html }} />*/}

        </article>

        {project.frontmatter.url &&
            <section className={bottomViewButtonContainer}>
                <Button icon={faEye} link={project.frontmatter.url}
                        type={"primary"}
                        text={"View " + project.frontmatter.title}
                        size={"lg"}/>
            </section>
        }

        {relatedContent.length ? <section id={"related-content"}>
            <h2>
                <FontAwesomeIcon icon={faProjectDiagram} className={"fa-fw header-icon graph-icon"}/>
                Related content
            </h2>
            {relatedContent.map(relatedContent => relatedContent.component)}
        </section> : ""}

        <section>
            <Button link="./" icon={faArrowLeft} text="Back to projects" local={true}/>
        </section>
    </>
}

export default Project

export const query = graphql`query ($slug: String!, $name: String!) {
    mdx(fields: {slug: {eq: $slug}}) {
        body
        frontmatter {
            cover {
                id
                publicURL
                childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH,
                        placeholder: BLURRED,
                        formats: [AUTO, PNG, WEBP]
                    )
                }
            }
            description
            github
            role
            technologies
            title
            url
            date
            technologies
            tags
            related
            github
            tableOfContents
        }
        tableOfContents
        relatedSocialMediaPosts {
            ...SocialMediaPostFragment
        }
        relatedProjects {
            ...ProjectOverviewFragment
        }
        parent {
            ... on File {
                mtime
            }
        }
    }
    allPressMentionsJson(
        filter: {projects: {in: [$name]}}
        sort: {fields: [date], order: DESC}
    ) {
        edges {
            node {
                id
                ...MentionFragment
            }
        }
    }
    allPapersJson(
        filter: {projects: {in: [$name]}}
        sort: {fields: [date], order: DESC}
    ) {
        edges {
            node {
                id
                ...PaperFragment
            }
        }
    }
    relatedTalks: allTalksJson(filter: {projects: {in: [$name]}}, sort: {fields: date, order: ASC}) {
        edges {
            node {
                id
                ...TalkFragment
            }
        }
    }
    site {
        siteMetadata {
            author
        }
    }
}
`

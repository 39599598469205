// extracted by mini-css-extract-plugin
export var backgroundColor = "#141821";
export var borderRadius = ".15rem";
export var bottomViewButtonContainer = "project-module--bottom-view-button-container--1fd59";
export var buttons = "project-module--buttons--de9b0";
export var cover = "project-module--cover--166aa";
export var coverContainer = "project-module--cover-container--d544f";
export var creation = "project-module--creation--41531";
export var creationDate = "project-module--creation-date--a4d09";
export var creationMetadata = "project-module--creation-metadata--aa0fc";
export var dataName = "project-module--data-name--2338c";
export var heading = "project-module--heading--40bb2";
export var icon = "project-module--icon--bfd44";
export var maxModalWidth = "48em";
export var metadata = "project-module--metadata--44b9e";
export var metadataContainer = "project-module--metadata-container--9618d";
export var metadataSectionTitle = "project-module--metadata-section-title--43931";
export var metadataSectionTitleButton = "project-module--metadata-section-title-button--ad33a";
export var modalBackgroundColor = "rgba(20,24,33,.75)";
export var projectName = "project-module--project-name--a6634";
export var role = "project-module--role--ec3fe";
export var tag = "project-module--tag--bf432";
export var tagMetadata = "project-module--tag-metadata--a9f65";
export var technology = "project-module--technology--20543";
export var textSm = "project-module--textSm--ef610";
export var tweetMentions = "project-module--tweet-mentions--1fd84";